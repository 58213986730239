import { POSITION, useToast } from 'vue-toastification';
import type { ToastOptions } from 'vue-toastification/src/types';
import CartToastContainerContent from '~/components/shop/CartToastContainerContent.vue';

const DEFAULT_OPTIONS: ToastOptions = {
  position: POSITION.BOTTOM_RIGHT
};

export function useShowToast() {
  const toast = useToast();
  const showToast = (message: string, options?: ToastOptions) =>
    toast(
      {
        component: CartToastContainerContent,
        // Any prop can be passed, but don't expect them to be reactive
        props: {
          message: message
        }
      },
      {
        ...DEFAULT_OPTIONS,
        ...options
      }
    );

  return {
    showToast
  };
}
